[wchfsInput] {
  box-sizing: border-box;
  height: 36px;
  width: 100%;
  border: 1px solid var(--grey-5);
  border-radius: 2px;
  background-color: var(--white);
  font-size: 13px;
  padding: 0 30px 0 10px;
  color: var(--grey-2);
  font-weight: 500;
  font-family: var(--grey-1);

  &:focus {
    outline: 0 none;
  }

  &:disabled {
    cursor: default;
    color: var(--grey-4);
    border-bottom: 1px solid var(--grey-5);
    background: var(--grey-6);
  }

  @include placeholder {
    font-family: var(--grey-1);
    color: var(--grey-4);
    font-size: 13px;
    font-weight: 400;
    line-height: 37px;
  }
}

[wchfsInput][readonly] {
  border: none;
  background: none;
  font-weight: 500;
}

[wchfsInput].wchfs-hint.ng-pristine {
  border-color: var(--primary-3);
}

input.ng-touched.ng-invalid[wchfsInput] {
  border-color: var(--supporting-1);
  @include box-shadow(0 1px 6px 0 rgba(var(--supporting-1-rgb), 0.18));
}

::ng-deep .ng-touched.ng-invalid[wchfsInput] {
  input {
    border: 1px solid var(--supporting-1);
    border-color: var(--supporting-1);
  }
}

textarea[wchfsInput] {
  padding-top: 10px;
  font-family: var(--grey-1);
  font-weight: 500;
}

input[wchfsFilterDate] {
  letter-spacing: 1px;
  font-size: 14px;
}
