@each $colorName, $colorValue in $colors {
  // 1. Fill
  .svg-fill-#{$colorName} path {
    fill: $colorValue;
  }

  // 2. Stroke
  .svg-stroke-#{$colorName} path {
    stroke: $colorValue;
  }
}

// 3. Size
$svg-sizes: (
  x-small: 16px,
  small: 20px,
  budicon: 25px,
  regular: 36px,
  big: 48px,
  large: 64px,
  x-large: 115px,
);
@each $sizeName, $sizeValue in $svg-sizes {
  .svg-size-#{$sizeName} {
    width: $sizeValue;
    height: $sizeValue;
    display: flex;
    align-items: center;
  }
}

@for $i from 1 through 64 {
  .svg-size-#{$i} {
    width: #{$i}px;
    height: #{$i}px;
  }
}
