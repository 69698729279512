/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

@import "theme-variables";
@import "pso-color-palette";
@import "lib";
@import "node_modules/@angular/material/prebuilt-themes/deeppurple-amber";

// stylelint-disable-next-line selector-max-universal
* {
  box-sizing: border-box;
}

.loading-overlay {
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 2;
  }
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

input[type="number"] {
  // Remove arrows from input type number
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

[wchfsInput] {
  font-weight: 400;
}

textarea {
  border: 1px solid var(--grey-5);
  border-radius: 2px;
  background-color: var(--white);
  font-size: 12px;
  color: var(--grey-3);
  font-weight: 400;
  height: 74px;
  padding: 13px 10px;
  font-family: var(--font-family);
  resize: none;
  width: 100%;

  &::placeholder {
    font-family: var(--font-family);
    color: var(--grey-4);
    font-size: 12px;
    font-weight: 400;
  }

  &:focus {
    border-color: var(--primary);
    outline: 0 none;
    box-shadow: 0 1px 6px 0 rgba(var(--primary-rgb), 0.18);
  }
}

.add-new-tire-modal {
  .mdc-dialog__surface {
    position: relative;
    display: flex !important;
  }
}

.mdc-dialog__surface {
  padding: 20px;
}

.mat-menu-panel {
  background: var(--primary-w-white);
}
