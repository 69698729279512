@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap");

@import "theme/theme";
@import "theme/flex-layout";

body {
  display: block;
  width: 100%;
  margin: 0;
  height: 100%;
  font-family: var(--font-family);
  overflow-x: hidden;
  box-sizing: border-box;
}

.modal {
  .modal-line-separator {
    border-top: 1px solid $grey-5;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
    width: 100%;
    margin-bottom: 1rem;
  }
}

.freeze {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 2;
    opacity: 0.6;
    cursor: wait;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20vw;
    height: 100%;
    animation: 2s freeze forwards infinite linear;
    background: #e5e6e7;
    opacity: 1;
    z-index: 2;
    filter: blur(50px);
    pointer-events: none;
    opacity: 0.4;
  }
}

@keyframes freeze {
  0% {
    transform: translateX(-20vw);
  }
  100% {
    transform: translateX(120vw);
  }
}

.basic-display-table {
  margin-top: 20px;
  margin-bottom: 20px;
  display: table;
  text-align: left;
  width: 100%;
  overflow-wrap: break-word;
  border-collapse: collapse;
  line-height: 24px;

  thead {
    background: var(--grey-6);
  }

  * {
    font-size: 12px;
  }

  td,
  th {
    line-height: 24px;
    padding: 8px 16px;
  }

  th {
    font-weight: 500;
  }

  td {
    color: var(--grey-1);
  }

  tr {
    border-bottom: 1px solid var(--grey-5);

    &.clickable {
      cursor: pointer;
    }

    &.clickable:hover {
      background: var(--grey-6);
    }
  }
}

.full-page-loader {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

table {
  &[multitemplatedatarows] {
    overflow: visible;

    .cdk-cell {
      position: relative;
      z-index: 1;

      > div {
        overflow: hidden;
        position: relative;
        z-index: 2;
        width: 100%;
        text-align: inherit;
      }
    }
  }
}
