/**
  Default variables
  Feel free to overwrite in src/theme/theme-variables in your app
 */

// Typography
$font-primary: "Helvetica", sans-serif !default;
$font-secondary: "Arial", sans-serif !default;

$text-h1: (
  "size": 26px,
  "weight": 400,
);

$text-h2: (
  "size": 22px,
  "weight": 400,
);

$text-h3: (
  "size": 18px,
  "weight": 400,
);

$text-h4: (
  "size": 16px,
  "weight": 400,
);

$text-display-1: (
  "size": 16px,
  "weight": 500,
);

$text-display-2: (
  "size": 14px,
  "weight": 500,
);

$text-display-3: (
  "size": 12px,
  "weight": 500,
);

$text-body-1: (
  "size": 16px,
  "weight": 400,
);

$text-body-2: (
  "size": 14px,
  "weight": 400,
);

$text-body-3: (
  "size": 12px,
  "weight": 400,
);

$text-input-1: (
  "size": 12px,
  "weight": 400,
);

$text-input-2: (
  "size": 11px,
  "weight": 400,
);

$text-caption: (
  "size": 11px,
  "weight": 400,
);

$primary-button: (
  "size": 12px,
  "weight": 600,
);

$text-button: (
  "size": 12px,
  "weight": 500,
);

// Colors
$primary: #e5322c !default;
$secondary: #3c3e42 !default;
$warn: #fabb2b !default;
$error: #ee4446 !default;
$grey-1: #252629 !default;
$grey-2: #3c3e42 !default;
$grey-3: #6a6d75 !default;
$grey-4: #9b9fa8 !default;
$grey-5: #ced3db !default;
$grey-6: #f0f2f5 !default;
$white: #fff;
$supporting-1: #e5322c !default;
$supporting-2: #68bb4b !default;
$supporting-3: #325395 !default;
$additional-1: #ced9ef !default;
$additional-2: #dbdde0 !default;
$additional-3: #f3f4f5 !default;
$blue-grey-1: #353c49 !default;
$blue-grey-2: #495365 !default;
$blue-grey-3: #6e7c98 !default;
$blue-grey-4: #97a9cb !default;
$blue-grey-5: #ced9ef !default;
$blue-grey-6: #ebf0fa !default;

// Variables - navigation
$navigation-line: #f0f2f5 !default;
$navigation-border: #e6e8eb !default;
$navigation-active-link: #e4312b !default;

$navigation-expand-sidebar-width: 265px;
$navigation-header-height: 62px;

$colors: (
  "primary": $primary,
  "secondary": $secondary,
  "warn": $warn,
  "error": $error,
  "grey-1": $grey-1,
  "grey-2": $grey-2,
  "grey-3": $grey-3,
  "grey-4": $grey-4,
  "grey-5": $grey-5,
  "grey-6": $grey-6,
  "white": $white,
  "supporting-1": $supporting-1,
  "supporting-2": $supporting-2,
  "supporting-3": $supporting-3,
  "additional-1": $additional-1,
  "additional-2": $additional-2,
  "additional-3": $additional-3,
  "blue-grey-1": $blue-grey-1,
  "blue-grey-2": $blue-grey-2,
  "blue-grey-3": $blue-grey-3,
  "blue-grey-4": $blue-grey-4,
  "blue-grey-5": $blue-grey-5,
  "blue-grey-6": $blue-grey-6,
);

// Spacing
$base-spacing: 4px;

// Table
$table-border: 1px solid $additional-2;
$table-border-radius: 2px;
$table-padding: 10px 20px 25px;
$table-font-size: 12px;
$table-header-row-height: 56px;
$table-header-cell-color: $grey-1;
$table-header-cell-font-size: 12px;
$table-header-cell-font-weight: 500;
$table-header-border-bottom-color: #9ea1a8;
$table-row-height: 48px;
$table-row-horizontal-padding: 20px;
$table-row-border-bottom-color: $additional-2;
$table-cell-border-width: 1px;
$table-cell-color: $grey-1;
$snackbar-background: #444547;
$snackbar-error: #e5322c;
$snackbar-success: #68bb4b;
$snackbar-warning: #fabb2b;
$snackbar-font-size: 14px;

// Breakpoints
$screen-sm-breakpoints: 360px;

// Button
$button-border-radius: 4px !default;
