.wchfs-ripple {
  overflow: hidden;
  position: relative;

  &:not(:empty) {
    transform: translateZ(0);
  }
}

.wchfs-ripple.wchfs-ripple-unbounded {
  overflow: visible;
}

.wchfs-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
