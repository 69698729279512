@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  @each $colorName, $colorValue in $colors {
    --#{$colorName}: #{$colorValue};
    --#{$colorName}-rgb: #{hexToRGB($colorValue)};
  }

  --font-family: #{$font-primary};
  --font-family-secondary: #{$font-secondary};
  --base-spacing: #{$base-spacing};
}

@each $colorName, $colorValue in $colors {
  .text-#{$colorName} {
    color: var(--#{$colorName});
  }
  .color-#{$colorName} {
    color: var(--#{$colorName});
  }
  .bg-#{$colorName} {
    background-color: var(--#{$colorName});
  }

  .wchfs-icon-fill-#{$colorName} path {
    fill: var(--#{$colorName}) !important;
  }

  .wchfs-icon-stroke-#{$colorName} path {
    stroke: var(--#{$colorName}) !important;
  }

  wchfs-checkbox[color="#{$colorName}"] .wchfs-checkbox-wrapper.wchfs-checkbox-checked .wchfs-checkbox:after {
    background-color: var(--#{$colorName});
  }

  wchfs-badge[color="#{$colorName}"] {
    background-color: var(--#{$colorName});
  }

  wchfs-badge[textcolor="#{$colorName}"] {
    color: var(--#{$colorName});
  }

  wchfs-radio[color="#{$colorName}"] .wchfs-radio-wrapper.wchfs-radio-checked .wchfs-radio:after {
    background-color: var(--#{$colorName});
  }
}

@mixin colorable-background($colors, $opacity: 1) {
  @each $colorName, $colorValue in $colors {
    &[color="#{$colorName}"] {
      background-color: var(--#{$colorName});
    }
  }
}
