$directions: "", "x", "y", "t", "r", "b", "l";

@each $direction in $directions {
  @for $i from 1 through 10 {
    .p#{$direction}-#{$i} {
      @if $direction == "" {
        padding: $i * $base-spacing;
      } @else if $direction == "x" {
        padding-right: $i * $base-spacing;
        padding-left: $i * $base-spacing;
      } @else if $direction == "y" {
        padding-top: $i * $base-spacing;
        padding-bottom: $i * $base-spacing;
      } @else if $direction == "t" {
        padding-top: $i * $base-spacing;
      } @else if $direction == "r" {
        padding-right: $i * $base-spacing;
      } @else if $direction == "b" {
        padding-bottom: $i * $base-spacing;
      } @else if $direction == "l" {
        padding-left: $i * $base-spacing;
      } @else {
        @error "Unknown direction #{$direction}.";
      }
    }
    .m#{$direction}-#{$i} {
      @if $direction == "" {
        margin: $i * $base-spacing;
      } @else if $direction == "x" {
        margin-right: $i * $base-spacing;
        margin-left: $i * $base-spacing;
      } @else if $direction == "y" {
        margin-top: $i * $base-spacing;
        margin-bottom: $i * $base-spacing;
      } @else if $direction == "t" {
        margin-top: $i * $base-spacing;
      } @else if $direction == "r" {
        margin-right: $i * $base-spacing;
      } @else if $direction == "b" {
        margin-bottom: $i * $base-spacing;
      } @else if $direction == "l" {
        margin-left: $i * $base-spacing;
      } @else {
        @error "Unknown direction #{$direction}.";
      }
    }
  }
}
