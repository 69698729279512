/**
  App variables
  You can overwrite variables from here: projects/ui/src/scss/variables.scss
 */

// Typography
$font-primary: "Rubik", sans-serif;
$font-secondary: "museo-sans", sans-serif;

$text-h1: (
  "size": 26px,
  "weight": 400,
);

$text-h2: (
  "size": 22px,
  "weight": 400,
);

$text-h3: (
  "size": 18px,
  "weight": 400,
);

$text-h4: (
  "size": 16px,
  "weight": 400,
);

$text-display-1: (
  "size": 16px,
  "weight": 500,
);

$text-display-2: (
  "size": 14px,
  "weight": 500,
);

$text-display-3: (
  "size": 12px,
  "weight": 500,
);

$text-body-1: (
  "size": 16px,
  "weight": 400,
);

$text-body-2: (
  "size": 14px,
  "weight": 400,
);

$text-body-3: (
  "size": 12px,
  "weight": 400,
);

$text-input-1: (
  "size": 12px,
  "weight": 400,
);

$text-input-2: (
  "size": 11px,
  "weight": 400,
);

$text-caption: (
  "size": 11px,
  "weight": 400,
);

$primary-button: (
  "size": 14px,
  "weight": 500,
);

$text-button: (
  "size": 14px,
  "weight": 500,
);

$primary: #0867b5;
$secondary: #6e7c98;
$warn: #fabb2b;
$error: #ee4446;
$grey-1: #252629;
$grey-2: #3d3f42;
$grey-3: #6e7075;
$grey-4: #9ea1a8;
$grey-5: #dbdde0;
$grey-6: #f3f4f5;
$white: #fff;
$supporting-1: #e5322c;
$supporting-2: #1cd368;
$supporting-3: #0c4568;
$additional-1: #ced9ef;
$additional-2: #dbdde0;
$additional-3: #f3f4f5;

:root {
  --navigation-expand-sidebar-width: 265px;
  --navigation-header-height: 62px;
  --navigation-border: #e6e8eb;
  --navigation-active-link: #e4312b;
}
