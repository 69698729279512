// Migration from @angular/flex-layout
// See: https://github.com/angular/flex-layout/issues/1426#issuecomment-1304100298

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-15 {
  gap: 15px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.space-between {
  justify-content: space-between;
}

.jc-space-around {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
  align-content: flex-end;
}

.items-start {
  align-items: flex-start;
  align-content: flex-start;
}

.items-space-around {
  align-items: space-around;
  align-content: space-around;
}

.fx-flex-30 {
  flex: 1 1 100%;
  max-width: 30%;
}

.fx-flex-35 {
  flex: 1 1 100%;
  max-width: 35%;
}

.fx-flex-40 {
  flex: 1 1 100%;
  max-width: 40%;
}

.fx-flex-45 {
  flex: 1 1 100%;
  max-width: 45%;
}

.fx-flex-49 {
  flex: 1 1 100%;
  max-width: 49%;
}

.fx-flex-60 {
  flex: 1 1 100%;
  max-width: 60%;
}

.fx-flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
}
