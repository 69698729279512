.table-container {
  overflow-x: scroll;
  border: $table-border;
  border-radius: $table-border-radius;
  background: $white;
  padding: $table-padding;
}

.cdk-table {
  display: table;
  font-size: $table-font-size;
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
  border-collapse: collapse;
}

.cdk-table thead,
.cdk-table tbody,
.cdk-table tfoot,
cdk-header-row,
cdk-row,
cdk-footer-row,
[cdk-header-row],
[cdk-row],
[cdk-footer-row],
.cdk-table-sticky {
  background: inherit;
}

cdk-header-row,
th.cdk-header-cell {
  border-bottom-color: $table-header-border-bottom-color;
}

cdk-row,
cdk-footer-row,
td.cdk-cell,
td.cdk-footer-cell {
  border-bottom-color: $table-row-border-bottom-color;
}

cdk-header-row {
  min-height: $table-header-row-height;
}

cdk-row,
cdk-footer-row {
  min-height: $table-row-height;
}

cdk-row,
cdk-header-row,
cdk-footer-row {
  display: flex;
  // Define a border style, but then widths default to 3px. Reset them to 0px except the bottom
  // which should be 1px;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  align-items: center;
  box-sizing: border-box;

  // Workaround for https://goo.gl/pFmjJD in IE 11. Adds a pseudo
  // element that will stretch the row the correct height. See:
  // https://connect.microsoft.com/IE/feedback/details/802625
  &::after {
    display: inline-block;
    min-height: inherit;
    content: "";
  }
}

// Note: we use `first-of-type`/`last-of-type` here in order to prevent extra
// elements like ripples or badges from throwing off the layout (see #11165).
cdk-cell:first-of-type,
cdk-header-cell:first-of-type,
cdk-footer-cell:first-of-type {
  padding-left: $table-row-horizontal-padding;

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: $table-row-horizontal-padding;
  }
}

cdk-cell:last-of-type,
cdk-header-cell:last-of-type,
cdk-footer-cell:last-of-type {
  padding-right: $table-row-horizontal-padding;

  [dir="rtl"] & {
    padding-right: 0;
    padding-left: $table-row-horizontal-padding;
  }
}

cdk-cell,
cdk-header-cell,
cdk-footer-cell {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  word-wrap: break-word;
  min-height: inherit;
}

/**
 * Native HTML table structure
 */
table.cdk-table {
  border-spacing: 0;
}

tr.cdk-header-row {
  height: $table-header-row-height;
}

tr.cdk-row,
tr.cdk-footer-row {
  height: $table-row-height;
  outline: none;
}

th.cdk-header-cell {
  text-align: left;

  [dir="rtl"] & {
    text-align: right;
  }
}

th.cdk-header-cell,
td.cdk-cell,
td.cdk-footer-cell {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

td.cdk-cell {
  padding: 10px 0;
}

// Note: we use `first-of-type`/`last-of-type` here in order to prevent extra
// elements like ripples or badges from throwing off the layout (see #11165).
th.cdk-header-cell:first-of-type,
td.cdk-cell:first-of-type,
td.cdk-footer-cell:first-of-type {
  padding-left: $table-row-horizontal-padding;

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: $table-row-horizontal-padding;
  }
}

th.cdk-header-cell:last-of-type,
td.cdk-cell:last-of-type,
td.cdk-footer-cell:last-of-type {
  padding-right: $table-row-horizontal-padding;

  [dir="rtl"] & {
    padding-right: 0;
    padding-left: $table-row-horizontal-padding;
  }
}

.cdk-header-cell {
  color: $table-header-cell-color;
  font-size: $table-header-cell-font-size;
  font-weight: $table-header-cell-font-weight;
}

.cdk-cell,
.cdk-footer-cell {
  color: $table-cell-color;
}

tr.wchfs-detail-row {
  height: 0;
}

tr.wchfs-detail-row td {
  padding: 0;
}

tr.cdk-row:not(.wchfs-expanded-row):active {
  //background: $grey-6;
}

.cdk-row td {
  border-bottom-width: $table-cell-border-width;
}

.wchfs-element-detail {
  overflow: hidden;
  display: flex;
}

tr.wchfs-expanded-row {
  background: $grey-6;
  border-top: 1px solid $grey-4;
  position: relative;
}
