:root {
  // Brand colours
  --brand-logo-navy: #315296;
  --brand-logo-yellow: #fab900;
  // Primary colours
  --primary-gradient: linear-gradient(145deg, rgba(11, 162, 231, 1) 0%, rgba(28, 211, 104, 1) 100%);
  --primary-gradient-invert: linear-gradient(145deg, rgba(28, 211, 104, 1) 0%, rgba(11, 162, 231, 1) 100%);
  --light-blue-gradient: linear-gradient(
    180deg,
    rgba(251, 251, 251, 1) 0%,
    rgba(223, 233, 242, 1) 87%,
    rgba(209, 223, 238, 1) 100%
  );
  --primary-b-blue: #0867b5;
  --primary-bg-blue-grey: #6e7c98;
  --primary-y-yellow: #fabb2b;
  --primary-w-white: #ffffff;
  --blue-gradient: linear-gradient(180deg, rgba(13, 72, 108, 1) 0%, rgba(24, 133, 201, 1) 100%);
  // Secondary colours
  --secondary-b-lighten20: #3985c4;
  --secondary-b-lighten70: #c4daed;
  --secondary-b-lighten90: #f1f6fb;
  --secondary-bg-lighten20: rgba(110, 124, 152, 0.8); // TODO: Just should be HEX
  --secondary-bg-lighten70: #d4d8e0;
  --secondary-bg-lighten90: #f0f2f5;
  --secondary-y-lighten20: rgba(250, 187, 43, 0.8); // TODO: Just should be HEX
  --secondary-y-lighten70: #feebbf;
  --secondary-y-lighten90: #f8fef0;
  // System colours
  --signal-error-red: #e5322c;
  --signal-error-red-lighten20: #ea5b56;
  --signal-error-red-lighten50: #f29996;
  --signal-error-red-lighten90: #fceaea;
  --signal-success-green: #1cd368;
  --signal-success-green-lighten20: #86c96f;
  --signal-success-green-lighten50: #d9eed2;
  --signal-success-green-lighten90: #f0f8ed;
  // UI colours
  --ui-g1-gray1: #262729;
  --ui-g2-gray2: #3d3f42;
  --ui-g3-gray3: #6e7075;
  --ui-g4-gray4: #9ea1a8;
  --ui-g5-gray5: #dbdde0;
  --ui-g6-gray6: #f3f4f5;
  --ui-g7-gray7: #fbfbfb;
  // Supporting colours
  --supporting-1-orange: #f5812d;
  --supporting-2-dark-green: #2d6a43;
  --supporting-3-darken-red: #d92f2a;
  --supporting-4-purple: #8a388a;
  --supporting-5-light-blue: #0ba2e7;
  --supporting-6-sea-green: #00a1a1;
  --supporting-7-navy: #0c4568;
  --supporting-7-lighten20: #3d6a86;
  --supporting-8-olive: #bab309;
  --supporting-9-kobalt: #0055ff;
  --supporting-10-forest-green: #148d2e;
  --supporting-11-magenta: #ff0099;
  // where is 11? :)
  --supporting-12-ice-blue: #04cfeb;
  --supporting-13-green: #1cd368;
  // INFO: if you add new color to palette, remember to add it to colors.model.ts too
}
