[routerLink] {
  cursor: pointer;
}

@mixin colorable-background-from-text-color($colors, $opacity: 1) {
  @each $colorName, $colorValue in $colors {
    &[textColor="#{$colorName}"] {
      background-color: rgba($colorValue, $opacity);
    }
  }
  &:not([textColor]) {
    background-color: rgba($grey-4, $opacity);
  }
}
