// Mixins

@mixin generateTypographyStyle($style) {
  font-size: map-get($style, "size");
  font-weight: map-get($style, "weight");
}

// Styles

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 2 * $base-spacing 0;
}

h1,
.text-h1 {
  @include generateTypographyStyle($text-h1);
}

h2,
.text-h2 {
  @include generateTypographyStyle($text-h2);
}

h3,
.text-h3 {
  @include generateTypographyStyle($text-h3);
}

h4,
.text-h4 {
  @include generateTypographyStyle($text-h4);
}

.text-display-1 {
  @include generateTypographyStyle($text-display-1);
}

.text-display-2 {
  @include generateTypographyStyle($text-display-2);
}

.text-display-3 {
  @include generateTypographyStyle($text-display-3);
}

.text-body-1 {
  @include generateTypographyStyle($text-body-1);
}

.text-body-2 {
  @include generateTypographyStyle($text-body-2);
}

.text-body-3 {
  @include generateTypographyStyle($text-body-3);
}

.text-input-1 {
  @include generateTypographyStyle($text-input-1);
}

.text-input-2 {
  @include generateTypographyStyle($text-input-2);
}

.text-caption {
  text-transform: uppercase;
  @include generateTypographyStyle($text-caption);
}

.text-primary-button {
  @include generateTypographyStyle($text-button);
}

.text-button {
  @include generateTypographyStyle($text-button);
}

// Specific styles

.text-grey-1.text-caption {
  color: $grey-4;
}
